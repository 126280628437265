exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-application-js": () => import("./../../../src/templates/Application.js" /* webpackChunkName: "component---src-templates-application-js" */),
  "component---src-templates-artical-js": () => import("./../../../src/templates/Artical.js" /* webpackChunkName: "component---src-templates-artical-js" */),
  "component---src-templates-company-page-js": () => import("./../../../src/templates/CompanyPage.js" /* webpackChunkName: "component---src-templates-company-page-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-document-js": () => import("./../../../src/templates/ProductDocument.js" /* webpackChunkName: "component---src-templates-product-document-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/ServicesPage.js" /* webpackChunkName: "component---src-templates-services-page-js" */)
}

